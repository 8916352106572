// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-ielts-academic-listening-js": () => import("./../../../src/pages/ielts-academic/listening.js" /* webpackChunkName: "component---src-pages-ielts-academic-listening-js" */),
  "component---src-pages-ielts-academic-reading-js": () => import("./../../../src/pages/ielts-academic/reading.js" /* webpackChunkName: "component---src-pages-ielts-academic-reading-js" */),
  "component---src-pages-ielts-academic-speaking-js": () => import("./../../../src/pages/ielts-academic/speaking.js" /* webpackChunkName: "component---src-pages-ielts-academic-speaking-js" */),
  "component---src-pages-ielts-academic-writing-js": () => import("./../../../src/pages/ielts-academic/writing.js" /* webpackChunkName: "component---src-pages-ielts-academic-writing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

